* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  
  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 50em;
  padding: 4em 1em;
  margin: 0 auto;
  color: #566b78;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background-image: url("images/watch.jpg");  
  border-radius: 1rem;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20vw 2em; 
}

 .main {
  text-align: center;
  margin: 1rem 0.5rem;
  background-color: #f5f7f9;
  border-radius: 1rem;
}

.intro {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 1rem 0;
  padding: 1rem 0.5rem;
}

.form {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 0.5rem;
}

.balance {
  padding: 0 0.5rem;
}

h2 {
  color: #e81c4f;
} 

h3 {
  margin-top: 1em;
  padding-top: 1em;
} 

.mainQuestion {
  text-align: left;
}

.main input {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

button {
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 1rem;
  font-size: 1.2rem;
  border: 1px solid #e81c4f;
  color: #e81c4f;
  transition: none 200ms ease-out;
  transition-property: color, background;
}

button:hover {
  background:  #e81c4f;
  color: white;
  cursor: pointer;
}

label {
 margin: 2rem 0;
 line-height: 1.5rem;
 font-size: 1.2rem;
 width: 100%;
}

.habitQuestion {
  display: block;
  text-align: left;
}

.ageQuestion {
  display: block;
  text-align: left;
  margin-top: 2rem;
}

select {
  margin: 1rem 0;
  border: none;
  border-radius: 4px;
  color: #566b78;
  font-size: 1rem;
  font-weight: 600;
  height: 3.9rem;
  background: #e5e9ff;
  width: 100%;
  padding:  20px;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.summary {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  padding: 1rem 0.5rem;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: blue;
}

/* TABLET */
@media (min-width: 668px) and (max-width: 1024px) {
  .header {
    padding: 20vw 2em;
  }

  .intro {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin: 1rem 0;
    padding: 1rem 0.5rem;
  }

  .form {
    padding: 1rem 1rem;
  }

  .balance {
    padding: 0 1rem;
  }

  h3, .mainQuestion, .ageQuestion, .habitQuestion {
    font-size: 1.4rem;
  } 

  select {
    font-size: 1.4rem;
    height: 4rem;
  }
}
/* DESKTOP */
@media (min-width: 1025px) and (max-width: 1450px){
  .header {
    padding: 15vw 2em;
  }
}
@media (min-width: 1450px) {
  .header {
    padding: 10vw 2em;
  }
}

@media (min-width: 1025px) {
  .intro {
    margin: 0 auto;
    line-height: 1.5rem;
  }

  .ageQuestion {
    text-align: center;
    margin: 2rem 4rem;
  }

  .mainQuestion, .habitQuestion  {
    text-align: center;
    text-justify: auto;
  }

  /* .habitQuestion {
    text-align: center;
    text-justify: auto;
  } */

  select {
    font-size: 1.2rem;
    width: 50%;
  }

  h3, .mainQuestion {
    font-size: 1.3rem;
  } 

  label {
    line-height: 1.5rem;
    font-size: 1.3rem;
   }
}
